import { FormGroup } from '@angular/forms';

export class WizardFormBase {

  detailForm: FormGroup;
  currentStep = 0;

  triggerValidateForm() {
    for (const key of Object.keys(this.detailForm.controls)) {
      this.detailForm.controls[key].markAsDirty();
      this.detailForm.controls[key].markAsTouched();
    }
  }

  handleBackBtnClick() {
    this.currentStep--;
  }

  getFormControlError(key: string): boolean {
    return this.detailForm.get(key).dirty && this.detailForm.get(key).invalid;
  }
}
